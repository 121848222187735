<template>
    <div id="commodityOperate">
        <div class="refuse-box" v-if="refuse">
            <p class="title">未通过原因</p>
            <p class="inner">{{refuse}}</p>
        </div>
        <div class="top-box"></div>

        <div class="cover-box">
            <p class="title">上传封面</p>
            <span class="tips">最多上传5张，大小不超过3M</span>
            <van-uploader
                v-model="fileList"
                :max-count="5"
                accept="image/*"
                :max-size="3 * 1024 * 1024"
                multiple
                @oversize="onOversize"
            />
        </div>

        <van-field v-model="name" label="商品名称" placeholder="请输入商品名称"/>
        <van-field
            label="存储方式"
            @click="openMethod"
            v-model="method"
            right-icon="arrow"
            readonly
            placeholder="请选择存储方式"
        />
        <van-field v-model="norms" label="规格" placeholder="例如 100g*10"/>
        <!--  单位-->
        <van-field
            label="单位"
            @click="unitPopup"
            v-model="unitselect"
            right-icon="arrow"
            readonly
            placeholder="请选择单位"
        />
        <!-- <van-field v-model="method" label="存储方式" placeholder="请输入存储方式" /> -->
        <van-field v-model="stock" label="库存" placeholder="请填写数字"/>
        <van-field v-model="keywords" label="关键字" placeholder="请填写关键字(选填)"/>

        <!--  分类-->
        <van-field
            label="分类"
            @click="openClassify"
            v-model="classify"
            right-icon="arrow"
            readonly
            placeholder="系统默认归类其他（选填）"
        />
        <van-field style="margin-bottom:13px;" v-model="address" label="产地" placeholder="请输入商品产地（选填）"/>

        <van-field v-model="price" label="售价" type="number" placeholder="请输入商品售价"/>
        <van-field v-model="favourable" label="优惠价" type="number" placeholder="优惠价必须小于售价（选填）"/>

        <div class="remarks-box">
            <p class="title">商品描述</p>
            <textarea v-model="reason" @input="reasonInput" placeholder="请输入商品介绍，不超过200字（选填）"></textarea>
            <p class="text">{{reasonNum}}</p>
        </div>

        <div class="btn-box">
            <div class="btn" @click="submit">保存</div>
        </div>

        <van-popup v-model="isClassifyShow" position="bottom" :style="{ height: '233px;' }">
            <van-picker
                ref="picker"
                show-toolbar
                value-key="category_name"
                :columns="columnsClassify"
                @confirm="confirmClassify"
                @cancel="isClassifyShow = false"
            />
        </van-popup>

        <van-popup v-model="isMethodShow" position="bottom" :style="{ height: '233px;' }">
            <van-picker
                ref="Mpicker"
                show-toolbar
                :default-index="MethodIndex"
                value-key="category_name"
                :columns="columnsMethod"
                @confirm="confirmMethod"
                @cancel="isMethodShow = false"
            />
        </van-popup>
        <!--   单位    -->
        <van-popup v-model="companyShow" position="bottom" :style="{ height: '233px;' }">
            <van-picker
                ref="picker"
                show-toolbar
                :default-index="UnitIndex"
                value-key="category_name"
                :columns="company"
                @confirm="confirmUnit"
                @cancel="isClassifyShow = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import {getUnitList, productClassify, productDetails, productHandle} from "../api/requist";

    export default {
        components: {},
        props: [],
        data() {
            return {
                company: [], // 单位
                indexArr: [],
                MethodIndex: 0,
                UnitIndex: 0,
                columnsClassify: [],
                columnsMethod: ["常温", "冷冻", "冷藏"],
                companyShow: false, // 单位弹窗
                isClassifyShow: false,
                isMethodShow: false,
                fileList: [],
                fileUrl: [],
                classify: "",
                name: "",
                norms: "",
                address: "",
                price: "",
                favourable: "",
                method: "",
                reason: "",
                reasonNum: "0/200",
                refuse: "",
                keywords: "",
                stock: "",
                unitselect: ""

            };
        },
        created() {
            if (this.$route.query.type === "edit") {
                this.getInfo();
            } else if (this.$route.query.type === "add") {
                this.getClassify();
            }
        },
        mounted() {
            this.getUnit()
        },
        watch: {},
        computed: {},
        methods: {
            // 单位 g，ml
            unitPopup() {
                this.companyShow = true;
                this.$nextTick(() => {
                    this.company.forEach((item, index) => {
                        if (item == this.unitselect) {
                            this.UnitIndex = index;
                        }
                    });
                });
            },
            // 分类
            openClassify() {
                this.isClassifyShow = true;
                this.$nextTick(() => {
                    this.$refs.picker.setIndexes(this.indexArr);
                });
            },
            // 存储方式
            openMethod() {
                this.isMethodShow = true;
                this.$nextTick(() => {
                    this.columnsMethod.forEach((item, index) => {
                        if (item == this.method) {
                            this.MethodIndex = index;
                        }
                    });
                });
            },
            async getClassify() {
                let that = this;
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                let res = await productClassify(formData);
                this.columnsClassify = res.data.map((item, index) => {
                    item.defaultIndex = index;
                    if (item.SubsetList) {
                        item.SubsetList.map((items, indexs) => {
                            return (items.defaultIndex = indexs);
                        });
                    }
                    item.children = item.SubsetList;
                    return item;
                });

                if (that.classify) {
                    this.columnsClassify.forEach((item, index) => {
                        item.children.forEach((items, indexs) => {
                            if (items.category_name === that.classify) {
                                that.indexArr[0] = index;
                                that.indexArr[1] = indexs;
                            }
                        });
                    });
                }
            },
            // 单位
            async getUnit() {
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                let res = await getUnitList(formData);
                if (res.code === 200) {
                    this.unit = res.data;
                    let selectedunits = ''
                    this.unit.forEach(item => {
                        selectedunits += item.unit_name + ""
                        let string = selectedunits
                        this.company = string.split("");
                    })
                }
            },
            dialog(title, message, confirm, cancel, backFn, beforeClose) {
                Dialog.confirm({
                    confirmButtonText: confirm,
                    cancelButtonText: cancel,
                    confirmButtonColor: "#FF6737",
                    cancelButtonColor: "#C0C4CC",
                    title: title,
                    message: message,
                    beforeClose: beforeClose,
                })
                    .then(() => {
                        backFn();
                    })
                    .catch(() => {
                    });
            },
            async submit() {
                let that = this;
                if (this.name === "") {
                    return Toast("请输入商品名称");
                }

                if (this.norms === "") {
                    return Toast("请输入商品规格");
                }

                if (this.unitselect === "") {
                    return Toast("请选择商品单位");
                }

                if (this.stock === "") {
                    return Toast("请填写商品库存");
                }

                // if (this.keywords === "") {
                //     return Toast("请填写关键字");
                // }
                if (this.price === "") {
                    return Toast("请填写商品售价");
                }


                if (+this.price <= 0) {
                    return Toast("商品售价必须大于0元");
                }

                if (Number(this.favourable) === "") {
                    this.favourable = this.price
                }
                if (+this.price == +this.favourable) {
                    this.favourable = this.price
                }
                if (+this.price < +this.favourable) {
                    return Toast("优惠价格需小于普通价格");
                }

                this.dialog(
                    "",
                    "新的商品信息需经过系统审核后生效审核过程中商品信息将无法修改预计1-2个工作日内完成审核",
                    "确认提交",
                    "再想一想",
                    function (a, d) {
                        let formData = new FormData();
                        let img_url = [];
                        that.fileList.forEach((item) => {
                            if (item.url) {
                                img_url.push(item.url);
                            }

                            if (item.file) {
                                formData.append("product_cover[]", item.file);
                            }
                        });
                        formData.append("merch_id", localStorage.getItem("merch_id"));
                        formData.append(
                            "product_id",
                            that.$route.query.id ? that.$route.query.id : ""
                        );

                        let category_id;
                        that.columnsClassify.forEach((item) => {
                            if (item.category_name == that.classify) {
                                return (category_id = item.id);
                            } else {
                                if (item.SubsetList) {
                                    item.SubsetList.forEach((items) => {
                                        if (items.category_name == that.classify) {
                                            return (category_id = items.id);
                                        }
                                    });
                                }
                            }
                        });
                        formData.append("product_name", that.name);
                        formData.append("category_id", category_id);
                        formData.append("unit", that.unitselect); // 单位
                        formData.append("stock", that.stock); // 库存
                        formData.append("keywords", that.keywords);  //关键字
                        formData.append("specifications", that.norms);
                        formData.append("place_production", that.address);
                        formData.append("storage_method", that.method);
                        formData.append("original_price", that.price);
                        formData.append(
                            "vip_price",
                            that.favourable ? that.favourable : that.price
                        );
                        formData.append("reason", that.reason);

                        formData.append("img_url", JSON.stringify(img_url));
                        productHandle(formData).then((res) => {
                            if (res.code === 200) {
                                if (that.$route.query.type === "edit") {
                                    Toast({
                                        message: "修改成功",
                                        duration: 500,
                                        onClose: function () {
                                            that.$router.back()
                                        },
                                    });
                                } else if (that.$route.query.type === "add") {
                                    Toast({
                                        message: "添加成功",
                                        duration: 500,
                                        onClose: function () {
                                            that.$router.back()
                                        },
                                    });
                                }
                            } else {
                                if (that.$route.query.type === "edit") {
                                    Toast("修改失败");
                                } else if (that.$route.query === "add") {
                                    Toast("添加失败");
                                }
                            }
                        });
                    },
                    function (a, d) {
                        d();
                    }
                );
            },
            onOversize(res) {
            },
            confirmClassify(info) {
                let that = this;
                this.classify = info[1];
                if (that.classify) {
                    this.columnsClassify.forEach((item, index) => {
                        item.children.forEach((items, indexs) => {
                            if (items.category_name === that.classify) {
                                that.indexArr[0] = index;
                                that.indexArr[1] = indexs;
                            }
                        });
                    });
                }
                this.isClassifyShow = false;
            },

            confirmMethod(info) {
                this.method = info;
                this.isMethodShow = false;
            },
            // 选择的单位
            confirmUnit(info) {
                this.unitselect = info;
                this.companyShow = false;
            },
            reasonInput() {
                if (this.reason.length >= 200) {
                    this.reason = this.reason.slice(0, 200);
                }
                this.reasonNum = this.reason.length + "/200";
            },
            async getInfo() {
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                formData.append("product_id", this.$route.query.id);
                let res = await productDetails(formData);
                if (res.code === 200) {
                    let url = res.data.product_cover.map((item) => {
                        return (item = {url: item});
                    });
                    this.fileList = url;
                    this.method = res.data.storage_method;
                    this.name = res.data.product_name;
                    this.norms = res.data.specifications;
                    this.address = res.data.place_production;
                    this.favourable = +res.data.vip_price == 0 ? '' : res.data.vip_price;
                    this.price = res.data.original_price;
                    this.classify = res.data.category_name;
                    this.reason = res.data.introduce;
                    this.refuse = res.data.refuse_reason;
                    this.stock = res.data.stock
                    this.keywords = res.data.keywords
                    this.unitselect = res.data.unit
                    this.getClassify();
                }
            },
        },
    };
</script>
<style lang="scss">
    .van-dialog {
        border-radius: 6px;
    }

    #commodityOperate {
        padding-bottom: 20px;
        box-sizing: border-box;
        min-height: 100vh;
        background: rgba(245, 247, 250, 1);

        .refuse-box {
            width: 100%;
            background: rgba(255, 103, 55, 0.6);
            padding: 12px 16px;
            box-sizing: border-box;

            .title {
                margin-bottom: 4px;
                text-align: left;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
            }

            .inner {
                font-size: 14px;
                color: rgba(255, 255, 255, 1);
                line-height: 16px;
                text-align: left;
            }
        }

        .top-box {
            height: 12px;
            background: rgba(245, 247, 250, 1);
        }

        .cover-box {
            padding: 12px 16px;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 12px;
            background: rgba(255, 255, 255, 1);

            .title {
                font-size: 14px;
                line-height: 14*1.4px;
                color: rgba(48, 49, 51, 1);
                text-align: left;
            }

            .tips {
                text-align: left;
                font-size: 12px;
                line-height: 12*1.4px;
                color: rgba(144, 147, 153, 1);
                display: block;
                margin-bottom: 10px;
            }

            .van-uploader {
                width: 100%;
            }

            .van-uploader__preview {
                margin-right: 12px;
            }

            .van-uploader__wrapper {
                width: 100%;
                display: flex;
                align-items: center;
            }

            .van-uploader__upload,
            .van-uploader__preview,
            .van-uploader__preview-image {
                height: 72px;
                width: 72px;

                input {
                    height: 72px;
                    width: 72px;
                }
            }
        }

        div {
            font-size: 14px;
        }

        .van-cell {
            font-size: 14px;
        }

        .remarks-box {
            position: relative;
            height: 192px;
            padding: 12px 16px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 1);

            .title {
                text-align: left;
                margin-bottom: 8px;
                font-size: 14px;
                color: rgba(48, 49, 51, 1);
            }

            textarea {
                height: 140px;
                background: rgba(245, 247, 250, 1);
                border-radius: 4px;
                resize: none;
                outline: none;
                border: none;
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
                color: #303133;
                font-size: 14px;

                &::placeholder {
                    color: #909399;
                }
            }

            .text {
                position: absolute;
                text-align: right;
                font-size: 3.2 * 3.75px;
                font-weight: 400;
                color: rgba(48, 49, 51, 1);
                bottom: 24px;
                right: 28px;
            }
        }

        .btn-box {
            height: 74px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                height: 46px;
                background: rgba(255, 103, 55, 1);
                border-radius: 2px;
                font-size: 16px;
                line-height: 46px;
                text-align: center;
                width: calc(100vw - 16px * 2);
                margin: 13px auto 0;
                color: rgba(255, 255, 255, 1);
            }
        }

        .van-picker__toolbar {
            .van-picker__confirm {
                color: #ff6737;
            }
        }
    }
</style>
